import merge from 'lodash/merge';

const defaultConfig = {
    url: '/widgets/index/refreshStatistic',
};

export async function sendShopwareStatistics(params = {}, config = {}) {
    config = merge({}, defaultConfig, config);

    const urlParams = new URLSearchParams();
    urlParams.append('requestPage', window.location.pathname || '');

    const url = new URL(window.location.href);

    addPartner(url, urlParams);
    addReferer(url, urlParams);

    for (const [key, value] of Object.entries(params))
        urlParams.append(key, value);

    await fetch(`${config.url}?${urlParams.toString()}`);
}

/**
 * @param {URL} url
 * @param {URLSearchParams} params
 */
function addPartner(url, params) {
    const partner = url.searchParams.get('sPartner');

    if (partner) {
        params.append('partner', partner);
    }
}

/**
 * @param {URL} url
 * @param {URLSearchParams} params
 */
function addReferer(url, params) {
    const referer = document.referrer || '';

    if (referer.length !== 0) {
        params.append('referer', referer);
    }
}
