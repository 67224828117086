const productratings = ({ productNumber }) => ({
    page: 1,
    reachedLastPage: false,

    async loadNextPage() {
        const page = this.page + 1;

        const response = await fetch(`/product-ratings/${productNumber}-${page}.html`);
        if (!response.ok) {
            this.reachedLastPage = true;
            return;
        }

        this.page = page;

        const tmpDiv = document.createElement("div");
        tmpDiv.innerHTML = await response.text();

        for (const rating of [...tmpDiv.childNodes]) {
            this.$refs.listContainer.appendChild(rating);
        }
    },

    loadMoreAnchor: {
        "data-x-on:click.prevent": "loadNextPage()",
        "data-x-show": "!reachedLastPage",
    },
});

export function registerProductratings(alpine) {
    alpine.data("productratings", productratings);
}
