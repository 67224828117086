/**
 * @typedef {Object} CsrfConfig
 * @property {string|undefined} token
 * @property {string} generateUrl
 * @property {string} cookieName
 * @property {string} headerName
 */

const getCsrfConfig = (() => {
    let initPromise = undefined;

    return async function() {
        if (!initPromise)
            initPromise = initializeCsrf(window.csrfConfig);

        return initPromise.then(() => window.csrfConfig);
    };
})();

/**
 * @param {CsrfConfig} csrfConfig
 * @returns {Promise<void>}
 */
async function initializeCsrf(csrfConfig) {
    if (Boolean(csrfConfig.token)) return;

    csrfConfig.token = getCookie(csrfConfig.cookieName);
    if (Boolean(csrfConfig.token)) return;

    csrfConfig.token = await fetchNewCsrfToken(csrfConfig);
    document.cookie = `${csrfConfig.cookieName}=${csrfConfig.token}; secure; path=/`;
}

/**
 * @param {CsrfConfig} csrfConfig
 * @returns {Promise<string>}
 */
async function fetchNewCsrfToken(csrfConfig) {
    const response = await fetch(csrfConfig.generateUrl, {credentials: 'same-origin'});
    const token = response.headers.get(csrfConfig.headerName);

    if (!Boolean(token)) {
        throw `Failed to fetch csrf token from shopware`;
    }

    return token;
}

function getCookie(name) {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (name == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
}

function applyCsrfToken(csrfConfig, headers = {}) {
    headers[csrfConfig.headerName] = csrfConfig.token;

    return Object.assign(
        {},
        headers,
        {[csrfConfig.headerName]: csrfConfig.token}
    );
}

export {
    getCsrfConfig,
    applyCsrfToken,
}
