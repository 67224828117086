/**
 * @typedef {Object} CustomerGroupConfig
 * @property {boolean} useDiscount
 * @property {boolean} displayGrossPrices
 */

/**
 * @returns {Promise<CustomerGroupConfig>}
 */
const getCustomerGroupConfig = (() => {
    let fetchPromise = undefined;

    return async function () {
        if (!fetchPromise)
            fetchPromise = fetchCustomerGroupConfig();

        return fetchPromise;
    };
})();

/**
 * @returns {Promise<CustomerGroupConfig>}
 */
async function fetchCustomerGroupConfig() {
    const response = await fetch('/staticcompanion/customergroup');
    return await response.json();
}

export {
    getCustomerGroupConfig,
}
