import merge from 'lodash/merge';
import {dataElementSelector, manageElementClasses} from 'js/utils/dom';

const log = (msg, ...a) => console.log(`tabs: ${msg}`, ...a);

/**
 * @callback PanelVisibilityListener
 * @param {HTMLElement} el
 * @param {boolean} isVisible
 */

/**
 * @typedef {Object} TabsConfig
 * @property {TabsConfigElements} elements
 * @property {TabsConfigClasses} classes
 * @property {number} activeTabIdxOnInit
 * @property {PanelVisibilityListener[]} panelVisibilityListeners
 */

/**
 * @typedef {Object} TabsConfigElements
 * @property {string} container
 * @property {string} button
 * @property {string} panel
 */

/**
 * @typedef {Object} TabsConfigClasses
 * @property {string[]} panelHide
 * @property {string[]} buttonActive
 * @property {string[]} buttonInActive
 */

/**
 * @type {TabsConfig}
 */
const defaultConfig = {
    elements: {
        container: 'tab-container',
        button: 'tab-button',
        panel: 'tab-panel',
    },
    classes: {
        panelHide: [],
        buttonActive: [],
        buttonInActive: [],
    },
    panelVisibilityListeners: [],
    activeTabIdxOnInit: 0,
};

/**
/**
 * @param {TabsConfig} config
 */
export function tabs(config) {
    config = merge({}, defaultConfig, config);

    const tabContainers = document.querySelectorAll(dataElementSelector(
        config.elements.container
    ));

    return [...tabContainers].map(el => initTabContainer(el));

    /**
     * @param {HTMLElement} tabContainerEl
     */
    function initTabContainer(tabContainerEl) {
        const buttons = [...tabContainerEl.querySelectorAll(dataElementSelector(
            config.elements.button
        ))];

        const panels = [...tabContainerEl.querySelectorAll(dataElementSelector(
            config.elements.panel
        ))];

        const activate = idx => activateTab({buttons, panels}, idx);

        if (buttons.length !== panels.length) {
            log('found buttons panels amount mismatch; abort init', {
                buttons: buttons.length,
                panels: panels.length,
                containerEl: tabContainerEl,
            });

            return;
        }

        if (buttons.length === 0) {
            log('found no buttons and panels; abort init', {
                containerEl: tabContainerEl,
            });

            return;
        }

        if (config.activeTabIdxOnInit !== null) {
            activate(config.activeTabIdxOnInit);
        }

        tabContainerEl.addEventListener('click', event => {
            const button = event.target.closest(dataElementSelector(config.elements.button));
            if (button === null) return;

            const buttonIdx = buttons.indexOf(button);
            if (buttonIdx === -1) return;

            activate(buttonIdx);
        });

        return {
            activate,
        }
    }

    function activateTab({buttons, panels}, activeIdx) {
        for (let idx = 0; idx < buttons.length; idx++) {
            const button = buttons[idx];
            const panel = panels[idx];
            const isActive = idx === activeIdx;

            manageElementClasses(button, !isActive, ...config.classes.buttonInActive);
            manageElementClasses(button, isActive, ...config.classes.buttonActive);
            manageElementClasses(panel, !isActive, ...config.classes.panelHide);

            (config.panelVisibilityListeners || []).forEach(listener => listener(panel, isActive));
        }
    }
}
