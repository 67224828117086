import merge from 'lodash/merge';
import {getDataElOptional, getDataEl, manageElementClasses} from "js/utils/dom";
import {applyCsrfToken} from "js/utils/csrf";

const defaultConfig = {
    elements: {
        form: 'newsletter',
        input: 'newsletter-input',
        notification: 'newsletter-notification',
    },
    urls: {
        subscribeUrl: '/newsletter',
    },
};

export function newsletterForm(csrfConfig, config = {}) {
    config = merge({}, defaultConfig, config);

    const form = getDataElOptional(config.elements.form);
    // abort initialization if there is no form element
    if (!form) return;

    const state = {
        wasSubmitted: false,
    };

    const globalElements = {
        form: form,
        input: getDataEl(config.elements.input, { parent: form }),
        notification: getDataElOptional(config.elements.notification, { parent: form }),
    };

    function setState(newState) {
        Object.assign(state, newState);
        updateDOM();
    }

    const updateDOM = (() => {
        let renderRequest;

        return () => {
            if (renderRequest) {
                window.cancelAnimationFrame(renderRequest);
            }

            renderRequest = window.requestAnimationFrame(() => {
                manageElementClasses(globalElements.form, !state.wasSubmitted, 'block');
                manageElementClasses(globalElements.form, state.wasSubmitted, 'invisible');

                if (globalElements.notification) {
                    manageElementClasses(globalElements.notification, state.wasSubmitted, 'block');
                    manageElementClasses(globalElements.notification, !state.wasSubmitted, 'hidden');
                }
            });
        };
    })();

    globalElements.form.addEventListener('submit', event => {
        event.preventDefault();

        const email = (globalElements.input.value || '').trim();
        if (email.length === 0) return;

        createNewsletterSubscription(email);
    });

    async function createNewsletterSubscription(email) {
        const postData = new FormData();
        postData.append('subscribeToNewsletter', 1);
        postData.append('newsletter', email);
        postData.append('privacycheckbox', 'on');

        const response = await fetch(config.urls.subscribeUrl, {
            method: 'post',
            headers: applyCsrfToken(csrfConfig),
            body: postData,
            credentials: 'same-origin',
        });

        if (response.status === 200) {
            globalElements.input.value = '';

            setState({
                wasSubmitted: true,
            });
        }
    }

    updateDOM();
}
