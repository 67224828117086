export function clearElementChilds(element) {
    while (element.firstChild) {
        element.removeChild(element.lastChild);
    }
}

export function dataElementSelector(value) {
    return `[data-element="${value}"]`;
}

/**
 * @typedef {Object} GetDataElOptions
 * @property {?Element} parent
 */

/**
 * @param {string} dataElement
 * @param {GetDataElOptions} options
 * @returns {Element}
 */
export function getDataEl(dataElement, options = {}) {
    const selector = dataElementSelector(dataElement);
    const parent = options.parent || document;

    if (parent instanceof Element && parent.matches(selector))
        return parent;

    const el = parent.querySelector(selector);

    if (el === null)
        throw `Missing element with data-element=${dataElement}`;

    return el;
}

/**
 * @param {string} dataElement
 * @param {GetDataElOptions} options
 * @returns {Element|null}
 */
export function getDataElOptional(dataElement, options) {
    try {
        return getDataEl(dataElement, options);
    } catch {
        return null;
    }
}

export function manageElementClasses(element, add, ...classNames) {
    if (add) {
        element.classList.add(...classNames);
    } else {
        element.classList.remove(...classNames);
    }
}
