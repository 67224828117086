import {cart} from './components/cart';
import {getCsrfConfig} from './utils/csrf';
import searchBox from "./components/search";
import {newsletterForm} from "js/components/newsletter-form";
import {sendShopwareStatistics} from "js/utils/sw-statistics";
import { tabs } from "./components/tabs";
import { customerGroupPrices } from './components/customer-group-prices';
import { customerGroupElements } from './components/customer-group-elements';
import { megaMenu } from "./components/mega-menu";
import Alpine from "alpinejs";
import { registerLazytabs } from "./components/lazytabs";
import {registerProductratings} from "./components/product-ratings";
import {abandonedPageTracker} from "./utils/abandoned-page-tracker";
import setupExitIntent from "./utils/exit-intent";

const apt = abandonedPageTracker({
    reactionDelayMs: 20000,
});

apt.addReaction(() => {
    const origTitle = document.title;
    const newTitle = "❤️ Wir vermissen Dich";
    let toggle = true;

    const intervalId = setInterval(
        () => {
            document.title = toggle
                ? newTitle
                : origTitle;

            toggle = !toggle;
        },
        500,
    );

    return () => {
        document.title = origTitle
        clearInterval(intervalId);
    };
});

window.Alpine = Alpine;
Alpine.prefix('data-x-');

Alpine.store("mhmenu", {
    open: false,

    toggleOpen() {
        this.open = !this.open;
    }
});

registerLazytabs(Alpine);
registerProductratings(Alpine);

Alpine.data("mhmenu", () => ({
    activeMenuId: 1,
    hierarchy: {},
    navTransition: [],
    startNavTransition(sourceId, targetId) {
        this.navTransition = [sourceId, targetId];
    },
    endNavTransition() {
        this.navTransition = [];
    },
    openButton: {
        ['data-x-on:click']() {
            this.$store.mhmenu.toggleOpen();
        }
    }
}));

Alpine.data("mhmenuOpenToggleButton", () => ({
    openButton: {
        ['data-x-on:click']() {
            this.$store.mhmenu.toggleOpen();
        },
    },
}));

Alpine.data("mhmenuItem", (id, parentId) => ({
    parentId,

    init() {
        this.hierarchy = {
            ...this.hierarchy,
            [parentId]: [...(this.hierarchy[parentId] || []), id]
        };

        this.$el.addEventListener(
            "transitionend",
            () => {
                this.endNavTransition();
            },
            true
        );
    },

    get isParent() {
        return this.hierarchy[id]?.includes(this.activeMenuId);
    },

    get isVisible() {
        return this.activeMenuId == id;
    },

    get inNavTransition() {
        return this.navTransition.includes(id);
    },

    ul: {
        ['data-x-bind:class']() {
            return this.cls;
        },
    },

    get cls() {
        return {
            "mhmenu-visible": this.isVisible || this.inNavTransition,
            "mhmenu-translateX": this.isVisible,
            "mhmenu-translateX-right": !this.isVisible && !this.isParent,
            "mhmenu-translateX-left": this.isParent,
            "mhmenu-transition": this.inNavTransition
        };
    },

    doStuff(targetId) {
        this.startNavTransition(id, targetId);
        this.activeMenuId = targetId;
    }
}));

Alpine.data("mhmenuLink", (targetId) => ({
    anchor: {
        "data-x-on:click"(event) {
            event.preventDefault();
            this.doStuff(targetId);
        }
    }
}));

Alpine.data("modal", () => ({
    open: false,
    anchor: {
        'data-x-on:keydown.window.escape'() {
            this.open = false;
        },
        "data-x-on:click"() {
            this.open = true;
        },
    },
    backgroundOverlay: {
        'data-x-transition:enter': "ease-out duration-300",
        'data-x-transition:enter-start': "opacity-0",
        'data-x-transition:enter-end': "opacity-100",
        'data-x-transition:leave': "ease-in duration-200",
        'data-x-transition:leave-start': "opacity-100",
        'data-x-transition:leave-end': "opacity-0"
    },
    panel: {
        'data-x-transition:enter': "ease-out duration-300",
        'data-x-transition:enter-start': "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
        'data-x-transition:enter-end': "opacity-100 translate-y-0 sm:scale-100",
        'data-x-transition:leave': "ease-in duration-200",
        'data-x-transition:leave-start': "opacity-100 translate-y-0 sm:scale-100",
        'data-x-transition:leave-end': "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
        'data-x-on:click.outside'() {
            this.open = false;
        },
    },
    exitButton: {
        'data-x-on:click'() {
            this.open = false;
        },
    },
}));


Alpine.start();

(async () => {
    const csrfConfig = await getCsrfConfig();

    cart(csrfConfig);
    searchBox();

    newsletterForm(csrfConfig);
    newsletterForm(csrfConfig, {
        elements: {
            form: 'exit-intent-newsletter',
            notification: 'exit-newsletter-notification'
        },
    });
    sendShopwareStatistics();

    tabs({
        classes: {
            panelHide: ['hidden'],
            buttonActive: ['tab-active'],
        },
    });

    tabs({
        elements: {
            container: 'gallery',
            button: 'gallery-thumb',
            panel: 'gallery-big',
        },
        classes: {
            panelHide: ['hidden'],
            buttonActive: ['bg-gray-50'],
        },
    });

    megaMenu({
        elements: {
            container: 'nav-tabs-container',
            button: 'nav-tabs-button',
            panel: 'nav-tabs-panel',
        },
    });

    customerGroupPrices();
    customerGroupElements();

})();

setupExitIntent(10000);

