/**
 * @typedef {Object} AbandonedPageTrackerConfig
 * @property {number} reactionDelayMs
 */

/**
 * @param {AbandonedPageTrackerConfig} config
 */
export function abandonedPageTracker(config) {
    const state = {
        reactionTimeoutId: undefined,
        reactions: [],
        reactionResets: [],
    };

    function onPageAbandoned() {
        state.reactionResets = state.reactions
            .map(r => r())
            .filter(Boolean);
    }

    function onPageUsed() {
        for (const resetFn of state.reactionResets) {
            resetFn();
        }
    }

    document.addEventListener("visibilitychange", () => {
        clearTimeout(state.reactionTimeoutId);

        if (document.hidden) {
            state.reactionTimeoutId = setTimeout(
                onPageAbandoned,
                config.reactionDelayMs,
            );
        } else {
            onPageUsed();
        }
    });

    return {
        addReaction(fn) {
            state.reactions.push(fn);
        },
    };
}

