/**
 * @typedef {Object} VisibilityTransitionConfig
 * @property {VisibilityTransitionConfigClasses} classes
 * @property {number} animationTimeout Delays the application of hideVisibility classes
 */

/**
 * @typedef {Object} VisibilityTransitionConfigClasses
 * @property {string[]} showVisibility
 * @property {string[]} showOpacity
 * @property {string[]} hideVisibility
 * @property {string[]} hideOpacity
 */

/**
 * @callback VisibilityTransitionHandler
 * @param {HTMLElement} el
 * @param {boolean} isVisible
 */

/**
 * @param {VisibilityTransitionConfig} config
 * @returns {VisibilityTransitionHandler}
 */
export function visibilityTransition(config) {
    const animationTimeouts = new WeakMap();

    return (el, isVisible) => {
        clearTimeout(animationTimeouts.get(el) || null);

        if (isVisible) {
            el.classList.add(...config.classes.showVisibility, ...config.classes.showOpacity);
            el.classList.remove(...config.classes.hideVisibility, ...config.classes.hideOpacity);
        } else {
            el.classList.remove(...config.classes.showOpacity);
            el.classList.add(...config.classes.hideOpacity);

            animationTimeouts.set(el, setTimeout(
                () => {
                    el.classList.remove(...config.classes.showVisibility);
                    el.classList.add(...config.classes.hideVisibility);
                },
                config.animationTimeout,
            ));
        }
    };
}
