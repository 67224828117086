import axios from "axios";
import {clearElementChilds, dataElementSelector, getDataEl, manageElementClasses} from "js/utils/dom";
import debounce from 'lodash/debounce';
import merge from 'lodash/merge';
import {visibilityTransition} from "../utils/visibility-transition";

const log = (msg, ...a) => console.log(`searchbox: ${msg}`, ...a);

/**
 * @typedef {Object} SearchBoxConfig
 * @property {SearchBoxConfigElements} elements
 * @property {string} searchApiUri
 * @property {number} minQueryLength
 * @property {number} animationTimeout
 */

/**
 * @typedef {Object} SearchBoxConfigElements
 * @property {string} box
 * @property {string} boxLoader
 * @property {string} boxIcon
 * @property {string} input
 * @property {string} icon
 * @property {string} resultListContainer
 * @property {string} resultList
 * @property {string} resultItemTemplate
 * @property {string} resultItemUrl
 * @property {string} resultItemName
 */

/**
 * @typedef {Object} SearchResultItem
 * @property {string} name
 * @property {string} imageSrcSet
 * @property {string} price
 * @property {string} uri
 */

/** @type {SearchBoxConfig} **/
const defaultConfig = {
    elements: {
        box: 'search-box',
        boxLoader: 'search-box-loader',
        boxIcon: 'search-box-icon',
        visibilityContainer: 'search-visibility-container',
        input: 'search-input',
        icon: 'search-icon',
        resultListContainer: 'search-result-list-container',
        resultList: 'search-result-list',
        resultItemTemplate: 'search-result-item-template',
        noResultItemTemplate: 'search-no-result-item-template',
        resultItemUrl: 'search-result-item-url',
        resultItemName: 'search-result-item-name',
    },
    searchApiUri: '/staticcompanion/search',
    minQueryLength: 3,
    animationTimeout: 150,
};

/**
 * @param {SearchBoxConfig} config
 */
function searchBox(config = {}) {
    config = merge({}, defaultConfig, config);

    const state = {
        isVisible: false,
        query: "",
        results: undefined,
        isLoading: false,
        renderRequest: undefined,
        requestCancelToken: undefined
    };

    const globalElements = {
        resultListContainer: getDataEl(config.elements.resultListContainer),
        resultList: getDataEl(config.elements.resultList),
        resultItemTemplate: getDataEl(config.elements.resultItemTemplate),
        noResultItemTemplate: getDataEl(config.elements.noResultItemTemplate),
        input: getDataEl(config.elements.input),
        box: getDataEl(config.elements.box),
        boxIcon: getDataEl(config.elements.boxIcon),
        boxLoader: getDataEl(config.elements.boxLoader),
        icon: getDataEl(config.elements.icon),
        visibilityContainer: getDataEl(config.elements.visibilityContainer),
    };

    const searchVisibilityTransition = visibilityTransition({
        animationTimeout: config.animationTimeout,
        classes: {
            showVisibility: ['visible'],
            hideVisibility: ['invisible'],
            showOpacity: ['opacity-100'],
            hideOpacity: ['opacity-0'],
        },
    });

    function setState(newState) {
        Object.assign(state, newState);
        updateDOM();
    }

    function updateDOM() {
        if (state.renderRequest) {
            window.cancelAnimationFrame(state.renderRequest);
        }

        state.renderRequest = window.requestAnimationFrame(() => {
            searchVisibilityTransition(globalElements.visibilityContainer, state.isVisible);

            if (state.isVisible) {
                setTimeout(() => globalElements.input.focus(), config.animationTimeout);
            }

            // update loader state
            manageElementClasses(globalElements.boxIcon, state.isLoading, 'hidden');
            manageElementClasses(globalElements.boxLoader, !state.isLoading, 'hidden');

            // update result list
            resetResultsContainer();
            if (state.results !== undefined) {
                // show results container
                globalElements.resultListContainer.classList.remove('hidden');
                // fill results container
                const resultListItems =
                    state.results.length !== 0
                        ? state.results.map((result) => createResultListItem(result))
                        : [createNoResultListItem()];

                globalElements.resultList.append(...resultListItems);
            }
        });
    }

    function resetResultsContainer() {
        clearElementChilds(globalElements.resultList);
        globalElements.resultListContainer.classList.add('hidden');
    }

    /**
     * @param {SearchResultItem} result
     * @returns {HTMLLIElement}
     */
    function createResultListItem(result) {
        const el = cloneResultListItemElement();

        fillResultListItemElement(el, result);

        return el;
    }

    function cloneResultListItemElement() {
        return globalElements
            .resultItemTemplate
            .content
            .firstElementChild
            .cloneNode(true);
    }

    function cloneNoResultListItemElement() {
        return globalElements
            .noResultItemTemplate
            .content
            .firstElementChild
            .cloneNode(true);
    }

    /**
     * @param {HTMLElement} el
     * @param {SearchResultItem} result
     */
    function fillResultListItemElement(el, result) {
        // set url
        const anchorEl = el.querySelector(dataElementSelector(config.elements.resultItemUrl));
        anchorEl.href = result.uri;

        // set name
        const nameEl = el.querySelector(dataElementSelector(config.elements.resultItemName));
        nameEl.textContent = result.name;
    }

    function createNoResultListItem() {
        const el = cloneNoResultListItemElement();

        // set url
        const anchorEl = el.querySelector(dataElementSelector(config.elements.resultItemUrl));
        if (anchorEl) {
            anchorEl.href = '/';
        }

        // set name
        const nameEl = el.querySelector(dataElementSelector(config.elements.resultItemName));
        if (nameEl) {
            nameEl.textContent = 'Keine Produkte gefunden';
        }

        return el;
    }

    async function search() {
        // cancel currently running requests
        if (state.requestCancelToken) {
            state.requestCancelToken.cancel();
        }

        // abort search and clear the currently displayed results
        // if the entered search query does not met the min query length
        if (state.query.length < config.minQueryLength) {
            setState({ results: undefined });
            return;
        }

        // show loading indicator
        setState({ isLoading: true });

        const query = state.query;
        const tokenSource = axios.CancelToken.source();
        state.requestCancelToken = tokenSource;

        try {
            const response = await axios.get(config.searchApiUri, {
                params: { q: query },
                cancelToken: tokenSource.token
            });

            if (query === state.query) {
                setState({ results: response.data.data });
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                log("search request was cancelled", { query });
            } else {
                log("error during search request", { query, error });
            }
        }

        if (state.requestCancelToken === tokenSource) {
            state.requestCancelToken = undefined;
        }

        // hide loading indicator
        setState({ isLoading: false });
    }

    const debouncedSearch = debounce(search, 150);

    function searchHandler(event) {
        const query = (event.target.value || "").trim();
        setState({ query });
        debouncedSearch();
    }

    function setupSearchBoxVisibilityListeners() {
        const clickSelectors = [
            dataElementSelector(config.elements.icon),
            dataElementSelector(config.elements.box),
        ];

        document.addEventListener('click', event => {
            if (clickSelectors.find(s => event.target.closest(s) !== null) === undefined) {
                if (state.isVisible) {
                    setState({ isVisible: false });
                }
            } else {
                if (!state.isVisible) {
                    setState({ isVisible: true });
                }

                // clicks on result items should go through
                if (event.target.closest(dataElementSelector(config.elements.box)) === null) {
                    event.preventDefault();
                }
            }
        });
    }

    globalElements.input.addEventListener("input", searchHandler);

    // trigger a new search if the user clicked the search input field
    // and we currently have no results
    globalElements.input.addEventListener("click", (event) => {
        if (state.results === undefined) {
            searchHandler(event);
        }
    });

    setupSearchBoxVisibilityListeners();
    updateDOM();
}

export default searchBox;
