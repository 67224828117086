const lazytabs = ({ activeTabPath, tabChangeEvent = "lazytabs-loaded" }) => ({
    activeTabPath,
    tabContent: "",

    init() {
        this.$watch("activeTabPath", async (path) => {
            const response = await fetch(path);
            if (!response.ok) return;

            this.tabContent = await response.text();

            this.$nextTick(() => this.$dispatch(tabChangeEvent));
        });
    },

    isActiveTab(tabPath) {
        if (!tabPath || !this.activeTabPath) return false;

        const origin = window.location.origin;
        const activeTabPathUrl = new URL(this.activeTabPath, origin);
        const tabPathUrl = new URL(tabPath, window.location.origin);

        return activeTabPathUrl.pathname === tabPathUrl.pathname;
    },
});

const lazytabsLink = (target) => ({
    target,

    container: {
        'data-x-bind:class'() {
            return {
                active: this.isActive()
            };
        }
    },

    anchor: {
        "data-x-on:click"(event) {
            // console.log("trigger lazytabs link", { href: target, event });
            this.activeTabPath = target;
        }
    },

    isActive() {
        return this.isActiveTab(this.target);
    },
});

const lazytabsContent = () => ({
    content: {
        "data-x-init": "tabContent = $el.innerHTML",
        "data-x-html": "tabContent"
    }
});

export function registerLazytabs(alpine) {
    alpine.data("lazytabs", lazytabs);
    alpine.data("lazytabsLink", lazytabsLink);
    alpine.data("lazytabsContent", lazytabsContent);
}
