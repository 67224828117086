import merge from 'lodash/merge';
import {dataElementSelector, getDataEl, manageElementClasses} from "js/utils/dom";
import { getCustomerGroupConfig } from '../utils/customer-group';

/**
 * @typedef {Object} CustomerGroupElementsConfig
 * @property {string[]} netPriceElements
 * @property {string[]} grossPriceElements
 * @property {string[]} hideClasses
 */

const defaultConfig = {
    grossPriceElements: ['cart-total', 'advise-price'],
    netPriceElements: ['cart-total-net', 'advise-price-net'],
    hideClasses: ['hidden'],
};

/**
 * @param {CustomerGroupElementsConfig} config
 */
export async function customerGroupElements(config) {
    config = merge({}, config, defaultConfig);

    const customerGroupConfig = await getCustomerGroupConfig();

    function manageElementVisibility(selectors, hide) {
        for (const sel of selectors) {
            const els = document.querySelectorAll(dataElementSelector(sel));

            for (const el of els) {
                manageElementClasses(el, hide, ...config.hideClasses);
            }
        }
    }

    manageElementVisibility(config.grossPriceElements, !customerGroupConfig.displayGrossPrices);
    manageElementVisibility(config.netPriceElements, customerGroupConfig.displayGrossPrices);
}
