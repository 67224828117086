/**
 * @typedef {Object} MegaMenuConfig
 * @property {MegaMenuConfigElements} elements
 */

import {tabs} from "./tabs";
import {visibilityTransition} from "../utils/visibility-transition";
import {dataElementSelector} from "../utils/dom";

/**
 * @typedef {Object} MegaMenuConfigElements
 * @property {string} container
 * @property {string} button
 * @property {string} panel
 */

/**
 *
 * @param {MegaMenuConfig} config
 */
export function megaMenu(config) {
    const [tab] = tabs({
        elements: config.elements,
        classes: {
            buttonActive: ['border-neutral'],
            buttonInActive: ['border-transparent'],
        },
        panelVisibilityListeners: [
            visibilityTransition({
                animationTimeout: 500,
                classes: {
                    hideVisibility: ['invisible'],
                    showVisibility: ['visible'],
                    hideOpacity: ['opacity-0', 'translate-y-1'],
                    showOpacity: ['opacity-100', 'translate-y-0'],
                },
            }),
        ],
        activeTabIdxOnInit: -1,
    });

    if (!tab) return;

    document.addEventListener('click', event => {
        const container = event.target.closest(dataElementSelector(config.elements.container));

        if (container === null) {
            tab.activate(-1);
        }
    });
}
