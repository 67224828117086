import merge from 'lodash/merge';
import camelCase from 'lodash/camelCase';
import keyBy from 'lodash/keyBy';

const log = (msg, ...a) => console.log(`customer group prices: ${msg}`, ...a);

/**
 * @typedef {Object} CustomerGroupPricesConfig
 * @property {string} articleNumberDataAttributeName
 * @property {string} articlePriceTypeDataAttributeName
 * @property {string} pricesApiEndpoint
 */

/**
 * @type {CustomerGroupPricesConfig}
 */
const defaultConfig = {
    articleNumberDataAttributeName: 'article-number',
    articlePriceTypeDataAttributeName: 'article-price-type',
    articlePriceQuantityFromAttributeName: 'article-price-quantity-from',
    pricesApiEndpoint: '/staticcompanion/prices',
};

export async function customerGroupPrices(config) {
    config = merge({}, defaultConfig, config);

    async function fetchArticlePrices(articleNumbers) {
        try {
            const response = await fetch(config.pricesApiEndpoint, {
                method: 'post',
                headers: {'content-type': 'application/json'},
                body: JSON.stringify(articleNumbers),
            });

            return await response.json();
        } catch (err) {
            log('failed to fetch article prices for the current customergroup', {err});

            return {};
        }
    }

    async function updatePrices() {
        const articleElements = document.querySelectorAll(
            `[data-${config.articleNumberDataAttributeName}]`
        );

        const dataAttrName = camelCase(config.articleNumberDataAttributeName);
        const articleElementsByNumber = keyBy([...articleElements], el => el.dataset[dataAttrName]);
        const articleNumbers = Object.keys(articleElementsByNumber);

        const articlePrices = await fetchArticlePrices(articleNumbers);
        const priceMapping = [
            {data: 'regular', api: 'regular'},
            {data: 'rebate', api: 'rebate'},
            {data: 'per-unit', api: 'priceperunit'},
        ];

        for (const [articleNumber, priceQtyRanges] of Object.entries(articlePrices)) {
            const articleEl = articleElementsByNumber[articleNumber];
            if (!articleEl) continue;

            for (const prices of priceQtyRanges) {
                for (const mapping of priceMapping) {
                    const priceEls = [...articleEl
                        .querySelectorAll(
                            [
                                `[data-${config.articlePriceTypeDataAttributeName}="${mapping.data}"]`,
                                `[data-${config.articlePriceQuantityFromAttributeName}="${prices.quantity.from}"]`,
                            ].join(''),
                        )];

                    if (prices.quantity.from === 1) {
                        priceEls.push(...articleEl.querySelectorAll(
                            [
                                `[data-${config.articlePriceTypeDataAttributeName}="${mapping.data}"]`,
                                `:not([data-${config.articlePriceQuantityFromAttributeName}]`,
                            ].join(''),
                        ));
                    }

                    const price = prices[mapping.api];
                    for (const priceEl of priceEls) {
                        priceEl.innerText = price;
                    }
                }
            }
        }
    }

    document.addEventListener('articles-loaded', () => updatePrices());

    updatePrices();
}
