import merge from 'lodash/merge';
import {dataElementSelector, getDataEl, manageElementClasses} from "js/utils/dom";
import {applyCsrfToken} from "js/utils/csrf";

const log = (msg, ...a) => console.log(`cart: ${msg}`, ...a);

/**
 * @typedef {Object} CartConfig
 * @property {CartConfigElements} elements
 * @property {CartConfigUrls} urls
 * @property {number} hideCartTimeout
 * @property {number} cartAnimationTimeout
 */

/**
 * @typedef {Object} CartConfigElements
 * @property {string} cart
 * @property {string} cartIcon
 * @property {string} cartLoader
 * @property {string} cartVoucherContainer
 * @property {string} cartVoucherInput
 * @property {string} cartVoucherBtn
 * @property {string} cartVoucherAddBtn
 * @property {string} cartVoucherError
 * @property {string} cartVoucherErrorText
 * @property {string} cartShippingCosts
 * @property {string} cartAmount
 * @property {string} cart
 * @property {string} addToCartBtn
 * @property {string} addToCartQuantity
 * @property {string} cartItemContainer
 * @property {string} cartItemTemplate
 * @property {string} cartItemEmptyTemplate
 * @property {string} cartItemArticleName
 * @property {string} cartItemAmount
 * @property {string} cartItemQuantity
 * @property {string} cartItemQuantityIncr
 * @property {string} cartItemQuantityDecr
 * @property {string} cartItemImage
 * @property {string} cartItemRemoveBtn
 * @property {string} cartItemSupplierName
 * @property {string} cartAction
 * @property {string} cartQuantity
 */

/**
 * @typedef {Object} CartConfigUrls
 * @property {string} index
 * @property {string} add
 * @property {string} delete
 * @property {string} addVoucher
 */

/**
 * @typedef {Object} CartItem
 * @property {string} id
 * @property {string} number
 * @property {string} name
 * @property {string} amount
 * @property {string} quantity
 * @property {string|null} url
 * @property {string|null} supplierName
 * @property {Array<MediaInfo>} image
 */

/**
 * @typedef {Object} MediaInfo
 * @property {string} source
 * @property {string} retinaSource
 * @property {string} sourceSet
 * @property {string} maxHeight
 * @property {string} maxWidth
 */

/**
 * @type {CartConfig}
 */
const defaultConfig = {
    urls: {
        index: '/staticcompanioncart/cart',
        add: '/staticcompanioncart/addArticle',
        update: '/staticcompanioncart/updateArticle',
        delete: '/staticcompanioncart/deleteArticle',
        addVoucher: '/staticcompanioncart/addVoucher',
    },
    elements: {
        cart: 'cart',
        cartBackground: 'cart-background',
        cartContainer: 'cart-container',
        cartIcon: 'cart-icon',
        cartCloser: 'cart-closer',
        cartLoader: 'cart-loader',
        cartVoucherContainer: 'cart-voucher-container',
        cartVoucherBtn: 'cart-voucher-btn',
        cartVoucherInput: 'cart-voucher-input',
        cartVoucherAddBtn: 'cart-voucher-add-btn',
        cartVoucherError: 'cart-voucher-error',
        cartVoucherErrorText: 'cart-voucher-error-text',
        cartShippingCosts: 'cart-shipping-costs',
        cartAmount: 'cart-amount',
        addToCartBtn: 'cart-add-item',
        addToCartQuantity: 'cart-add-item-quantity',
        cartItemContainer: 'cart-item-list',
        cartItemTemplate: 'cart-item-template',
        cartItemEmptyTemplate: 'cart-item-empty-template',
        cartItemArticleName: 'cart-item-article-name',
        cartItemAmount: 'cart-item-amount',
        cartItemQuantity: 'cart-item-quantity',
        cartItemQuantityIncr: 'cart-item-quantity-incr',
        cartItemQuantityDecr: 'cart-item-quantity-decr',
        cartItemImage: 'cart-item-image',
        cartItemRemoveBtn: 'cart-item-remove',
        cartItemSupplierName: 'cart-item-supplier-name',
        cartAction: 'cart-action',
        cartQuantity: 'cart-quantity',
    },
    hideCartTimeout: 2 * 1000,
    cartAnimationTimeout: 500,
};

const CART_QUANTITY_INCR = 'incr';
const CART_QUANTITY_DECR = 'decr';

/**
 * @param {CsrfConfig} csrfConfig
 * @param {CartConfig} config
 */
export function cart(csrfConfig, config = {}) {
    config = merge({}, defaultConfig, config);

    const state = {
        renderRequest: undefined,
        isVisible: false,
        isLoading: false,
        isVoucherVisible: false,
        voucherErrors: [],
        cart: { items: [] },
    };

    const globalElements = {
        cart: getDataEl(config.elements.cart),
        cartBackground: getDataEl(config.elements.cartBackground),
        cartContainer: getDataEl(config.elements.cartContainer),
        cartIcon: getDataEl(config.elements.cartIcon),
        cartLoader: getDataEl(config.elements.cartLoader),
        cartVoucherContainer: getDataEl(config.elements.cartVoucherContainer),
        cartVoucherBtn: getDataEl(config.elements.cartVoucherBtn),
        cartVoucherInput: getDataEl(config.elements.cartVoucherInput),
        cartVoucherAddBtn: getDataEl(config.elements.cartVoucherAddBtn),
        cartVoucherError: getDataEl(config.elements.cartVoucherError),
        cartVoucherErrorText: getDataEl(config.elements.cartVoucherErrorText),
        cartItemContainer: getDataEl(config.elements.cartItemContainer),
        cartShippingCosts: getDataEl(config.elements.cartShippingCosts),
        cartAmount: getDataEl(config.elements.cartAmount),
        cartItemTemplate: getDataEl(config.elements.cartItemTemplate),
        cartItemEmptyTemplate: getDataEl(config.elements.cartItemEmptyTemplate),
        cartAction: getDataEl(config.elements.cartAction),
        cartQuantity: getDataEl(config.elements.cartQuantity),
    };

    function setState(newState) {
        Object.assign(state, newState);
        updateDOM();
    }

    const handleCartVisibility = (() => {
        let animationTimeout;

        return () => {
            clearTimeout(animationTimeout);

            const isVisible = state.isVisible;

            manageElementClasses(globalElements.cartLoader, !state.isLoading, 'hidden');
            manageElementClasses(globalElements.cartBackground, isVisible, 'opacity-100');
            manageElementClasses(globalElements.cartBackground, !isVisible, 'opacity-0');
            manageElementClasses(globalElements.cartContainer, isVisible, 'translate-x-0');
            manageElementClasses(globalElements.cartContainer, !isVisible, 'translate-x-full');

            if (isVisible) {
                manageElementClasses(globalElements.cart, true, 'opacity-100', 'visible');
                manageElementClasses(globalElements.cart, false, 'opacity-0', 'invisible');
            } else {
                animationTimeout = setTimeout(
                    () => manageElementClasses(globalElements.cart, true, 'opacity-0', 'invisible'),
                    config.cartAnimationTimeout
                );
                manageElementClasses(globalElements.cart, false, 'opacity-100', 'visible');
            }
        };
    })();

    function updateDOM() {
        if (state.renderRequest) {
            window.cancelAnimationFrame(state.renderRequest);
        }

        state.renderRequest = window.requestAnimationFrame(() => {
            handleCartVisibility();
            renderCartQuantity();

            if (state.isVisible) {
                renderCart();
            }
        });
    }

    function renderCartQuantity() {
        globalElements.cartQuantity.innerText = state.cart.items.length;
    }

    function renderCart() {
        renderCartItems();
        renderPricing();
        renderVoucher();

        manageElementClasses(globalElements.cartAction, state.cart.items.length === 0, 'hidden');
    }

    function renderCartItems() {
        clearCartItemContainer();

        if (state.cart.items.length > 0) {
            for (const item of state.cart.items) {
                const itemEl = createCartItemElement(item);

                globalElements.cartItemContainer.append(itemEl);
            }
        } else {
            const emptyEl = globalElements.cartItemEmptyTemplate
                .content
                .firstElementChild
                .cloneNode(true);

            globalElements.cartItemContainer.append(emptyEl);
        }
    }

    function clearCartItemContainer() {
        const childs = [...globalElements.cartItemContainer.children];

        childs
            .filter(c => c.matches(':not(template)'))
            .forEach(c => globalElements.cartItemContainer.removeChild(c));
    }

    /**
     * @param {CartItem} item
     * @returns {Node}
     */
    function createCartItemElement(item) {
        const el = globalElements
            .cartItemTemplate
            .content
            .firstElementChild
            .cloneNode(true);

        el.dataset['cartItemId'] = item.id;

        // set image
        const imgEl = el.querySelector(dataElementSelector(config.elements.cartItemImage));
        imgEl.src = getCartItemImage(item);

        // remove padding if not a rebate article
        if (!['rebate', 'voucher'].includes(item.type)) {
            imgEl.classList.remove('px-4');
            imgEl.classList.remove('py-3');
        }

        // set article name
        const nameEl = el.querySelector(dataElementSelector(config.elements.cartItemArticleName));
        nameEl.textContent = item.name;

        // set quantity
        const quantityEl = el.querySelector(dataElementSelector(config.elements.cartItemQuantity));
        quantityEl.value = item.quantity;

        // set amount
        const amountEl = el.querySelector(dataElementSelector(config.elements.cartItemAmount));
        amountEl.textContent = item.amount;

        // set article url
        if (Boolean(item.url)) {
            const anchorEl = imgEl.closest('a');
            anchorEl.href = item.url;
        }

        // set supplier name
        if (Boolean(item.supplierName)) {
            const supplierNameEl = el.querySelector(dataElementSelector(config.elements.cartItemSupplierName));
            supplierNameEl.textContent = item.supplierName;
        }

        return el;
    }

    /**
     * @param {CartItem} item
     * @returns {string}
     */
    function getCartItemImage(item) {
        const fallbackImage = 'https://cdn.hanfosan.de/assets/static/svg/icon_percentage_solid.svg';
        if (['rebate', 'voucher'].includes(item.type)) return fallbackImage;

        const image = (item.image[0] || {}).source;
        return image || fallbackImage;
    }

    function renderPricing() {
        globalElements.cartAmount.textContent = state.cart.amountTotal;
        globalElements.cartShippingCosts.textContent = state.cart.shippingCosts;
    }

    function renderVoucher() {
        manageElementClasses(globalElements.cartVoucherBtn, (state.cart.items || []).length === 0, 'hidden');
        manageElementClasses(globalElements.cartVoucherContainer, !state.isVoucherVisible, 'hidden');
        manageElementClasses(globalElements.cartVoucherError, state.voucherErrors.length === 0, 'hidden');

        globalElements.cartVoucherErrorText.textContent = state.voucherErrors.join('; ');
    }

    async function fetchCartState() {
        const response = await fetch(config.urls.index, {
            headers: applyCsrfToken(csrfConfig),
            credentials: 'same-origin',
        });
        const jsonData = await response.json();

        return jsonData.data;
    }

    async function triggerLoadingState(fn) {
        try {
            setState({ isLoading: true });
            return await fn();
        } finally {
            setState({ isLoading: false });
        }
    }

    function setupEventListeners() {
        setupCartVisibilityListeners();
        setupCartAddItemListeners();
        setupCartQuantityListeners();
        setupCartRemoveItemListeners();
        setupVoucherListeners();
    }

    function setupCartVisibilityListeners() {
        const cartClickSelectors = [
            dataElementSelector(config.elements.cartIcon),
            dataElementSelector(config.elements.cartBackground),
            dataElementSelector(config.elements.cartCloser),
        ];

        document.addEventListener('click', event => {
            if (cartClickSelectors.find(s => event.target.closest(s) !== null) === undefined) {
                return;
            }

            setState({ isVisible: !state.isVisible });
        });
    }

    function setupCartAddItemListeners() {
        document.addEventListener('click', event => {
            if (!event.target.matches(dataElementSelector(config.elements.addToCartBtn)))
                return;

            event.preventDefault();

            const articleNumberEl = event.target.closest(`[data-article-number]`);
            if (articleNumberEl === null) {
                log("Couldn't find article number for add to cart button");
                return;
            }

            const articleNumber = articleNumberEl.dataset['articleNumber'] || '';
            if (articleNumber.length === 0) {
                log("Found article number during add to cart, but it is empty!")
                return;
            }

            const quantityEl = articleNumberEl.querySelector(dataElementSelector(config.elements.addToCartQuantity));
            const quantity = quantityEl ? quantityEl.value : 1;

            triggerLoadingState(() => addArticleToCart(articleNumber, quantity));
        });
    }

    function determineCartQuantityEventType(event) {
        if (event.target.closest(dataElementSelector(config.elements.cartItemQuantityIncr)))
            return CART_QUANTITY_INCR;

        if (event.target.closest(dataElementSelector(config.elements.cartItemQuantityDecr)))
            return CART_QUANTITY_DECR;

        return null;
    }

    function findClosestCartItemId(event) {
        const cartItemIdEl = event.target.closest('[data-cart-item-id]');
        if (cartItemIdEl === null) return null;

        const cartItemId = cartItemIdEl.dataset['cartItemId'] || '';
        if (cartItemId.length === 0) return null;

        return parseInt(cartItemId);
    }

    function findCartItemById(id) {
        return state.cart.items
            .find(item => item.id == id);
    }

    function setupCartQuantityListeners() {
        globalElements.cartContainer.addEventListener('click', event => {
            const type = determineCartQuantityEventType(event);
            if (!type) return;

            const cartItemId = findClosestCartItemId(event);
            if (cartItemId === null) {
                log("Couldn't find cart item id in cart quantity listener");
                return;
            }

            const cartItem = findCartItemById(cartItemId);
            if (!cartItem) {
                log("Couldn't find cart item to modify in cart");
                return;
            }

            const relQuantityChange = type === CART_QUANTITY_INCR ? 1 : -1;
            const newQuantity = cartItem.quantity + relQuantityChange;

            triggerLoadingState(() => updateCartItemQuantity(cartItem.id, newQuantity));
        });

        globalElements.cartContainer.addEventListener("change", event => {
            if (!event.target.matches(dataElementSelector(config.elements.cartItemQuantity)))
                return;

            const cartItemId = findClosestCartItemId(event);
            if (cartItemId === null) {
                log("Couldn't find cart item id in cart quantity listener");
                return;
            }

            const cartItem = findCartItemById(cartItemId);
            if (!cartItem) {
                log("Couldn't find cart item to modify in cart");
                return;
            }

            const newQuantity = event.target.value;

            // update the current client side state to avoid re-rendering with the old value
            cartItem.quantity = newQuantity;

            triggerLoadingState(() => updateCartItemQuantity(cartItemId, newQuantity));
        });
    }

    function setupCartRemoveItemListeners() {
        document.addEventListener('click', event => {
            if (!event.target.matches(dataElementSelector(config.elements.cartItemRemoveBtn)))
                return;

            event.preventDefault();

            const cartItemId = findClosestCartItemId(event);
            if (cartItemId === null) {
                log("Couldn't find cart item id for remove from cart button");
                return;
            }

            triggerLoadingState(() => removeArticleFromCart(cartItemId));
        });
    }

    function setupVoucherListeners() {
        document.addEventListener('click', event => {
            if (event.target.closest(dataElementSelector(config.elements.cartVoucherBtn)) === null)
                return;

            event.preventDefault();

            setState({
                isVoucherVisible: !state.isVoucherVisible,
            });
        });

        document.addEventListener('click', event => {
            if (event.target.closest(dataElementSelector(config.elements.cartVoucherAddBtn)) === null)
                return;

            event.preventDefault();

            const voucherCode = (globalElements.cartVoucherInput.value || '').trim();
            if (voucherCode.length === 0)
                return;

            triggerLoadingState(() => addVoucherToCart(voucherCode));
        });
    }

    async function updateCartItemQuantity(cartItemId, quantity) {
        const postData = new FormData();
        postData.append("id", cartItemId);
        postData.append("quantity", quantity);

        const response = await fetch(config.urls.update, {
            method: "post",
            headers: applyCsrfToken(csrfConfig),
            body: postData,
            credentials: "same-origin",
        });

        const jsonData = await response.json();
        setState({
            cart: jsonData.data,
        });
    }

    async function addArticleToCart(articleNumber, quantity = 1) {
        setState({ isVisible: true });

        const postData = new FormData();
        postData.append('number', articleNumber);
        postData.append('quantity', quantity);

        const response = await fetch(config.urls.add, {
            method: 'post',
            headers: applyCsrfToken(csrfConfig),
            body: postData,
            credentials: 'same-origin',
        });

        const jsonData = await response.json();
        setState({
            cart: jsonData.data,
        });
    }

    async function removeArticleFromCart(cartItemId) {
        const postData = new FormData();
        postData.append('cartItemId', cartItemId);

        const response = await fetch(config.urls.delete, {
            method: 'post',
            headers: applyCsrfToken(csrfConfig),
            body: postData,
            credentials: 'same-origin',
        });

        const jsonData = await response.json();
        setState({
            cart: jsonData.data,
        });
    }

    async function addVoucherToCart(voucherCode) {
        const postData = new FormData();
        postData.append('voucher', voucherCode);

        const response = await fetch(config.urls.addVoucher, {
            method: 'post',
            headers: applyCsrfToken(csrfConfig),
            body: postData,
            credentials: 'same-origin',
        });

        if (response.status === 200) {
            globalElements.cartVoucherInput.value = '';

            const jsonData = await response.json();
            setState({
                cart: Object.assign(state.cart, jsonData.data || {}),
                voucherErrors: jsonData.errors || [],
            });
        } else {
            const jsonData = await response.json();
            setState({
                voucherErrors: jsonData.errors || [],
            });
        }
    }

    (async () => {
        setState({cart: await triggerLoadingState(fetchCartState)});
    })();

    setupEventListeners();
    updateDOM();
}
